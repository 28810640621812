import React, { useState, useEffect } from 'react';
import styles from './concerts.module.css';
import { useConcerts } from '../customHooks/useConcerts';

import BlueBox from '../components/BlueBox';
import BaroqueSeparator from '../components/UI/BaroqueSeparator';
import GoldLineSeparator from '../components/UI/GoldLineSeparator';
import WhiteLineSeparator from '../components/UI/WhiteLineSeparator';
import SingleConcert from '../components/SingleConcert';
import ConcertBoxNavigator from '../components/UI/ConcertBoxNavigator';
import Button from '../components/UI/Button';
import Quote from '../components/Quote';

import concertBild1 from '../img/konzerte_1.jpg';
import concertBild2 from '../img/konzerte_2.jpg';
import concertBild3 from '../img/konzerte_3.jpg';



const Concerts = (props) => {

    useEffect(()=> {
        props.onSiteLoad('concerts');
    }, [])

    const dataCtx= useConcerts();

    function sliceIntoChunks(arr, chunkSize) {
        const intoChunks = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            intoChunks.push(chunk);
        }
        return intoChunks;
    }
    
    const newConcertsIntoChunks = sliceIntoChunks(dataCtx.newConcerts, 3);
    const oldConcertsIntoChunks = sliceIntoChunks(dataCtx.oldConcerts, 3);


     
    const [activeConcertList, setActiveConcertList] = useState(1);
    const [oldOrNewConcerts, setOldOrNewConcerts] = useState('new');
    const [startRightToLeftAnimation, setStartRightToLeftAnimation] = useState(false);

    const setActiveConcertListHandler = (index) => {
        setStartRightToLeftAnimation(true);
        setTimeout(() => {setActiveConcertList(index)}, 500);
        setTimeout(() => {setStartRightToLeftAnimation(false)}, 1000);
    }

    const setOnNewConcertsHandler = () => {
        setStartRightToLeftAnimation(true);
        setTimeout(() => {setOldOrNewConcerts('new'); setActiveConcertList(1)}, 500);
        setTimeout(() => {setStartRightToLeftAnimation(false)}, 1000);
    }

    const setOnOldConcertsHandler = () => {
        setStartRightToLeftAnimation(true);
        setTimeout(() => {setOldOrNewConcerts('old'); setActiveConcertList(1)}, 500);
        setTimeout(() => {setStartRightToLeftAnimation(false)}, 1000);
    }

    return(
  
       <section className={styles.nextConcerts} aria-labelledby='Konzerte'>
        <BlueBox padding='40px' height={props.viewport > 430 ? '1200px' : '1300px'  } width= '100%' overflow='visible' style={{position:'relative'}}>
                <h2 id="Konzerte" className='white'>Alle Konzerttermine</h2>
                <GoldLineSeparator />

                <BaroqueSeparator />
            
                <div className={styles.buttons}> 
                <Button onClickFunction = {setOnNewConcertsHandler}
                        aria-pressed={oldOrNewConcerts==="new"}>kommende</Button>
                <Button onClickFunction = {setOnOldConcertsHandler}
                        aria-pressed={oldOrNewConcerts==="old"}>vergangene</Button>
                </div>

                <div aria-live="polite" className={startRightToLeftAnimation ? styles.swipeRightToLeft : ''}>
  {oldOrNewConcerts === 'new' && (
    Array.isArray(newConcertsIntoChunks[activeConcertList - 1]) &&
    newConcertsIntoChunks[activeConcertList - 1].length > 0 ? (
      newConcertsIntoChunks[activeConcertList - 1].map(concert => (
        <SingleConcert
          key={concert.id}
          datum={concert.datum}
          ort={concert.ort}
          ensemble={concert.ensemble}
          stadt={concert.stadt}
          titel={concert.titel}
        />
      ))
    ) : (
      <p className="white" style={{width: "100%", textAlign:"center"}}>Zurzeit keine kommenden Konzerte.</p>
    )
  )}

  {oldOrNewConcerts === 'old' && (
    Array.isArray(oldConcertsIntoChunks[activeConcertList - 1]) &&
    oldConcertsIntoChunks[activeConcertList - 1].length > 0 ? (
      oldConcertsIntoChunks[activeConcertList - 1].map(concert => (
        <SingleConcert
          key={concert.id}
          datum={concert.datum}
          ort={concert.ort}
          ensemble={concert.ensemble}
          stadt={concert.stadt}
          titel={concert.titel}
        />
      ))
    ) : (
      <output className="white">Keine vergangenen Konzerte vorhanden.</output>
    )
  )}
</div>

            <WhiteLineSeparator width= "100px" top = "10px"/>
            <div className ={styles.navigator}>
                <ConcertBoxNavigator index = {activeConcertList} 
                                    length = {oldOrNewConcerts === 'new' ? newConcertsIntoChunks.length : oldConcertsIntoChunks.length} 
                                    onSwitch = {setActiveConcertListHandler}/>
            </div>
        </BlueBox>


       {props.viewport >430 && <div className={styles.musikerBildContainer}>
            <img src = {concertBild1} className={styles.musikerBild}
            alt="Francesco Savignano sitzt mit seinem Kontrabass"/>
            <Quote quote='Savignano brachte sein Orchester zu herrlich "sprechender" Entfaltung durch prägnante Artikulation, überzeugende Tempi und Dynamik' author='Westdeutsche Allgemeine Zeitung' fontSize='14px' quoteWidth='5%'/>
            <img src = {concertBild2} className={styles.musikerBild}
            alt="Francesco Savignano dirigiert ein Orchester"/>
            <Quote quote='Der Dirigent setzte hohe Maßstäbe und seine vibrierende Körpersprache ergänzte die Tonmalereien des Orchesters ästhetisch' author='Rheinische Post' fontSize='14px' quoteWidth='5%' />
            <img src = {concertBild3} className={styles.musikerBild}
            alt="Francesco Savignano am Dirigentenpult"/>
            <Quote quote='Energetisch und kraftvoll' author='Westdeutsche Allgemeine Zeitung' fontSize='14px' quoteWidth='5%' />
            </div>}</section>
);
};

export default Concerts;