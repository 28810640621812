import {useState, useEffect, Fragment} from 'react';
import ReactDOM from 'react-dom';
import styles from './subNav.module.css';

import Backdrop from "../Backdrop";
import ContactForm from '../ContactForm';
import LinksModal from '../LinksModal';
import ImpressumModal from '../ImpressumModal';

const SubNav = (props) => {

    const backdropPortal = document.getElementById('backdrop');

    const modal = document.getElementById('modal');

    const [KontaktIsOn, setKontaktIsOn] = useState(false);
    const [LinksIsOn, setLinksIsOn] = useState(false);
    const [ImpressumIsOn, setImpressumIsOn] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (event.key === 'Escape' && (KontaktIsOn || LinksIsOn || ImpressumIsOn)) {
            reallyCloseNow(); 
          }
        };
      
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
      }, [KontaktIsOn, LinksIsOn, ImpressumIsOn]);
      


    const onKontaktClickHandler = () => {
        setKontaktIsOn(true);
        setIsClosing(true);
    }

    const onCloseHandler = () => {
        setIsClosing(false);
        setTimeout(reallyCloseNow, 501);
    }

    const onLinksClickHandler = () => {
        setLinksIsOn(true);
        setIsClosing(true);
    }

     const onImpressumClickHandler = () => {
        setImpressumIsOn(true);
        setIsClosing(true);
    }


    const reallyCloseNow = () => {
        if (KontaktIsOn) setKontaktIsOn(false);
        if (LinksIsOn) setLinksIsOn(false);
        if (ImpressumIsOn) setImpressumIsOn(false);
    }

    return (
        <Fragment>
            {(KontaktIsOn || LinksIsOn || ImpressumIsOn) && ReactDOM.createPortal(<Backdrop isOn={isClosing}/>, backdropPortal)}
            {KontaktIsOn && ReactDOM.createPortal(<ContactForm viewport = {props.viewport} isOn={isClosing} onModalClose={onCloseHandler}/>, modal)};
            {LinksIsOn && ReactDOM.createPortal(<LinksModal isOn={isClosing} onModalClose={onCloseHandler}/>, modal)};
            {ImpressumIsOn && ReactDOM.createPortal(<ImpressumModal isOn={isClosing} onModalClose={onCloseHandler}/>, modal)};
        <div className={styles.subNav_box}>
        <ul className={styles.subNav}>
            <li className={styles.list}>
                <button aria-label="Impressum öffnen" className={styles.mainLink} onClick = {onImpressumClickHandler} tabIndex={0}>Impressum</button>
                </li>
            <li className={styles.list}>
                <button aria-label="Links öffnen" className={styles.mainLink} onClick = {onLinksClickHandler} tabIndex={0}>Links</button>
                </li>
            <li className={styles.list}>
                <button aria-label="Kontaktformular öffnen" className={styles.mainLink} onClick = {onKontaktClickHandler} tabIndex={0}>Kontakt</button>
                </li>
        </ul>
    </div>
    </Fragment>
    );
}

export default SubNav;