import React, {Fragment, useEffect} from 'react';
import styles from './bio.module.css';
import bild from '../img/francesco_savignano_bio.jpg';
import BlueBox from '../components/BlueBox';
import BaroqueSeparator from '../components/UI/BaroqueSeparator';
import GoldLineSeparator from '../components/UI/GoldLineSeparator';
import pdf from '../docs/pressetext.pdf';
import LinkDownloadButton from '../components/UI/LinkDownloadButton';



const Bio = (props) => {

    useEffect(()=> {
        props.onSiteLoad('bio');
    }, [])



    return(
    <div className={styles.container}>
        <h1 className={styles.hidden}>Francesco Savignano - Biographie</h1>
       <main className={styles.main}>
            <BlueBox padding = '48px' height='100%'>
                <h2 className='white'>Ein Leben auf vier Saiten</h2>
                <GoldLineSeparator width='60%'/>
                <BaroqueSeparator />
                <p className='white'>Francesco Savignano sammelte seine ersten professionellen Orchestererfahrungen beim <em>Symphonieorchester des Bayerischen Rundfunks</em> und den <em>Münchner Philharmonikern</em>,
        bevor er 1992 seine Stelle bei den <em>Duisburger Philharmonikern</em> antrat.
</p> 
<p className='white'> Zu seinen zahlreichen musikalischen Interessen abseits des gängigen Orchesterrepertoires
    zählt seit Schultagen die historisch informierte Aufführungspraxis, wobei Ton Koopmans
    erstes Duisburger Gastdirigat den entscheidenden Impuls gab, sich intensiv mit dem historischen
    Kontrabass und Violone zu befassen. Seitdem wirkt er regelmäßig bei Konzerten und Aufnahmen
    so bekannter Ensembles wie <em>Nuovo Aspetto</em>, <em>Concerto Köln</em>, <em>Harmonie Universelle</em>, <em>L'Arte del Mondo</em> oder dem <em>Dresdner Festspielorchester</em> mit; ein besonderer Schwerpunkt liegt dabei auf dem sogenannten "Wiener Violone", auf dem er sich auch solistisch betätigt: so war er
    beispielsweise mit dem <em>Combattimento Consort</em> unter Jan Willem de Vriend im Amsterdamer
    Concertgebouw mit Werken von Franz Anton Hoffmeister zu Gast oder mit dem Ensemble <em>Nuovo Aspetto</em>
    im Rahmen der Duisburger Kammerkonzerte mit dem Concerto per il Contrabasso von Karl Kohaut.</p>
<p className='white'>Im Studienjahr 2020/21 erweiterte er zudem seinen Horizont an der Würzburger Musikhochschule
    bei Prof. Friederike Heumann mit einem Gaststudium auf der Viola-da-Gamba.</p>
<p className='white'> Ebenfalls seit Schülertagen ist die Salonmusik sein Gegengewicht zur "ernsten Muse", und nach
    Anfängen im Münchener <em>Salonquintett Rauscher</em> war er Mitglied des <em>Salonorchesters Tibor Jonas</em>
    (München), des <em>Johann Strauß Ensembles</em> (Duisburg) und spielt seit 2008 mit großem Vergnügen im
    <em>Tea Time Ensemble</em> (Düsseldorf).</p>
<p className='white'>  Darüber hinaus ist F.S. seit vielen Jahren als Dirigent tätig, zunächst als reiner Autodidakt,
    bevor er sich ab 2004 in einigen Meisterklassen, unter anderem bei Prof. Colin Metters
    (<em>Royal Academy of Music</em>) weiterbildete. Seit 2006 steht er als musikalischer Leiter dem
    <em>Sinfonieorchester Niederberg</em> vor, mit dem er seitdem zahlreiche Werke der Klassik und Romantik
    erarbeitet hat. 2009 debütierte er auch am Pult der <em>Duisburger Philharmoniker</em> und leitete seitdem
    eine Reihe musikalischer Produktionen - überwiegend im konzertpädagogischen Bereich -, die zum
    Teil auch als Aufnahmen erschienen sind.</p>

                <LinkDownloadButton className={styles.linkButton} color='#fdfffa' destination={pdf}>Pressetext</LinkDownloadButton>
            </BlueBox>
                {props.viewport >430 && <div className={styles.musikerBildContainer}>
                    <img src = {bild} alt="Francesco Savignano mit Kontrabass" role="presentation" className={styles.musikerBild}/></div>}
       </main>
       <div style ={{marginBottom: '40px'}}>
       </div>

    </div>);
};

export default Bio;