import React  from 'react';
import styles from './header.module.css';
import MainNav from './nav/MainNav';
import SocialNav from './nav/SocialNav';
import MobileNav from './nav/MobileNav';


const Header = (props) => {

    return(  
        <header className={styles.header}>
            <div className={styles.navigations}>
            {props.viewport > 1024 && <MainNav activeSite = {props.activeSite}/>}
            <SocialNav />
            {props.viewport <= 1024 && <MobileNav />}
            </div>
           <div className={styles.title}>
            <h1 className={styles.logo_Francesco} aria-label="Francesco">Francesco</h1>
            <h1 className={styles.logo_Savignano} aria-label="Savignano">Savignano</h1>
            </div>
            </header>
    );
}

export default Header;