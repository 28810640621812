import { NavLink } from "react-router-dom";
import { useState } from "react";
import styles from './mainNav.module.css';

const MainNav = (props) => {

    const [menuHover, setMenuHover] = useState(false);
    const [linkHover, setLinkHover] = useState(0);

    const pallinaStyles = (menuHover ? styles.pallinaMenuHover : styles.pallinaMenuOut) + ' ' +
        (linkHover === 1 ? styles.pallinaLinkHover1 : '') +
        (linkHover === 2 ? styles.pallinaLinkHover2 : '') + 
        (linkHover === 3 ? styles.pallinaLinkHover3 : '') +
        (linkHover === 4 ? styles.pallinaLinkHover4 : '');

    return (
        <nav aria-label="Hauptnavigation" role="navigation">
             <div className={styles.navWrapper} onMouseLeave={() => { setMenuHover(false); setLinkHover(0); }}>
            <ul className = {styles.mainNav} style={{width: props.width}}>
                <li 
                    onMouseEnter = {()=>{setLinkHover(1)}} 
                    onClick= {() => {window.scroll(0,0); setLinkHover(0)}}>
                    <NavLink to = 'home' 
                        className={
                            props.activeSite === 'home' ? styles.mainLinkActive : styles.mainLink
                        }
                        aria-label="Home" 
                        aria-current={props.activeSite === 'home' ? 'page' : undefined}>
                            Home
                            </NavLink>
                </li>
                <li onMouseEnter = {()=>{setLinkHover(2)}}
                    onClick= {() => {window.scroll(0,0); setLinkHover(0)}}>
                    <NavLink to = 'bio' 
                        className={
                            props.activeSite === 'bio' ? styles.mainLinkActive : styles.mainLink
                        }
                        aria-label="Bio" 
                        aria-current={props.activeSite === 'Bio' ? 'page' : undefined}>Bio
                        </NavLink>
                </li>
                <li onMouseEnter = {()=>{setLinkHover(3)}} 
                    onClick= {() => {window.scroll(0,0); setLinkHover(0)}}>
                    <NavLink to = 'concerts' 
                        className={
                            props.activeSite === 'concerts' ? styles.mainLinkActive : styles.mainLink
                            }
                        aria-label="concerts"
                        aria-current={props.activeSite === 'concerts' ? 'page' : undefined}>Konzerte
                        </NavLink>
                    </li>
                <li onMouseEnter = {()=>{setLinkHover(4)}} 
                    onClick= {() => {window.scroll(0,0); setLinkHover(0)}}
                    >
                    <NavLink to = 'media' 
                    className={
                        props.activeSite === 'media' ? styles.mainLinkActive : styles.mainLink
                        }
                        aria-label="media"
                        aria-current={props.activeSite === 'media' ? 'page' : undefined}>Media
                        </NavLink>
                    </li>
                
                
            </ul>
            <span className={pallinaStyles} aria-hidden="true"></span>
            </div>
        </nav>
    );
}

export default MainNav;