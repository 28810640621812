

import styles from './concertBoxNavigator.module.css';
import ArrowBackIconRounded from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardIconRounded from '@mui/icons-material/ArrowForwardRounded';

const ConcertBoxNavigator = (props) => {

    const setActiveConcertItem = (item) => {
        props.onSwitch(item);
    }

    return(

            <nav className={styles.container} role="navigation">
                {(props.index > 1) && 
                <ArrowBackIconRounded role="button" fontSize='small' className={styles.arrowBack}
                onClick = {() => {setActiveConcertItem(props.index-1)}} aria-label="vor"/> }
                <span role="button" 
                className={styles.navigationNumbersActive} 
                aria-current="true" 
                aria-label={`Seite ${props.index}`}>{props.index}</span>
                {(props.length >= (props.index +1)) && 
                <span role="button" 
                className={styles.navigationNumbers} 
                onClick = {() => {setActiveConcertItem(props.index+1)}}
                aria-label={`Seite ${props.index +1}`}>{props.index +1}</span>}
                {(props.length >= (props.index +2)) && 
                <span role="button" 
                className={styles.navigationNumbers} 
                onClick = {() => {setActiveConcertItem(props.index+2)}} 
                aria-label={`Seite ${props.index +2}`}>{props.index +2}</span>}
                {(props.length >= (props.index +3)) && 
                <ArrowForwardIconRounded role="button" fontSize='small' className={styles.arrowForward} onClick = {() => {setActiveConcertItem(props.index+1)}}/>}
            </nav>


    )
}

export default ConcertBoxNavigator;