import React from 'react';

const Pressetext = () => {
    return(
       <main>
        <p>Das ist Pressetext</p>
        </main>
    )
};

export default Pressetext;