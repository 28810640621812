import React, { Fragment, useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import styles from "./home.module.css";

import Quote from "../components/Quote";
import LinkButton from "../components/UI/LinkButton";
import BlueBox from "../components/BlueBox";
import BaroqueSeparator from "../components/UI/BaroqueSeparator";
import BlueLineSeparator from "../components/UI/BlueLineSeparator";
import WhiteLineSeparator from "../components/UI/WhiteLineSeparator";
import SingleConcert from "../components/SingleConcert";
import NewsModal from "../components/NewsModal";
import ProjectModal from "../components/ProjectModal";
import Button from "../components/UI/Button";
import Backdrop from "../components/Backdrop";
import Welcome from "../components/Welcome";

import musikerBild from "../img/francesco_savignano_home.jpg";

import { useConcerts } from "../customHooks/useConcerts";
import dataContext from "../store/dataContext";

const Home = (props) => {

  const API_URL = process.env.REACT_APP_API_URL;

  const dataCtx = useContext(dataContext);
  const concertCtx = useConcerts();
  const threeConcerts = concertCtx.newConcerts.slice(0, 3);

  const backdropPortal = document.getElementById("backdrop");

  const modal = document.getElementById("modal");

  const [welcomePageIsOn, setWelcomePageIsOn] = useState(true);
  const [newsModalIsOn, setNewsModalIsOn] = useState(false);
  const [projectModalIsOn, setProjectModalIsOn] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [welcomePageIsClosing, setWelcomePageIsClosing] = useState(false);


 
  const onNewsModalClickHandler = () => {
    setNewsModalIsOn(true);
    setIsClosing(true);
  };

  const onProjectModalClickHandler = () => {
    setProjectModalIsOn(true);
    setIsClosing(true);
  };

  const onCloseHandler = () => {
    setIsClosing(false);
    setTimeout(reallyCloseNow, 501);
  };

  const onWelcomePageCloseHandler = () => {
    setIsClosing(true);
    setTimeout(reallyCloseNow, 501);
  };

  const reallyCloseNow = () => {
    if (welcomePageIsOn) {setWelcomePageIsOn(false); dataCtx.setVisited()};
    if (newsModalIsOn) setNewsModalIsOn(false);
    if (projectModalIsOn) setProjectModalIsOn(false);
  };

  const getAllNews = () => {
    fetch(
      `${API_URL}/controller/newsTasks.php`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((allNewsData) => {
        if (allNewsData) {
          dataCtx.getNews(allNewsData.data);
        }
      });
  };

  const getAllProjects = () => {
    fetch(
      `${API_URL}/controller/projectTasks.php`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((allProjectData) => {
        if (allProjectData) {
          dataCtx.getProjects(allProjectData.data);
        }
      });
  };

  useEffect(() => {
    props.onSiteLoad("home");
    getAllNews();
    getAllProjects();
  }, []);

  const lastNews = dataCtx.news.reverse().slice(0, 1);
  const lastNewsImageURL = `${API_URL}/images/${lastNews[0].imgFilename}`;
  const lastNewsTitle = lastNews[0].newsTitle;
  const lastNewsText = lastNews[0].newsText;

  const lastProject = dataCtx.projects.reverse().slice(0, 1);
  const lastProjectImageURL = `${API_URL}/images/${lastProject[0].imgFilename}`;
  const lastProjectTitle = lastProject[0].projectTitle;
  const lastProjectSubtitle = lastProject[0].projectSubtitle;
  const lastProjectText = lastProject[0].projectText;


  return (
    <Fragment>
      <h1 className={styles.hidden}>Francesco Savignano</h1>
      <Quote
        quote="Spielfreude und hervorragendes Können"
        author="Westdeutsche Allgemeine Zeitung"
        width="80%"
        margin={props.viewport > 430 ? "60px auto" : "30px auto 20px"}
      />
      
      <BlueLineSeparator width="30%" />

      <section className={styles.nextConcerts}>
        <BlueBox padding="40px" height={props.viewport > 430 ? "974px" : "1000px" } width={props.viewport > 430 ? "100%" : "90%"} margin="0 auto">
          <h2 className="white">Nächste Termine</h2>
          <BaroqueSeparator />
          {threeConcerts.map((concert) => {
            return (
              <SingleConcert
                key={concert.id}
                datum={concert.datum}
                ort={concert.ort}
                ensemble={concert.ensemble}
                stadt={concert.stadt}
                titel={concert.titel}
              />
            );
          })}

          <LinkButton destination="/site/concerts" color="white" aria-label="Mehr Konzerte">
            Mehr Konzerte
          </LinkButton>
        </BlueBox>

        {props.viewport > 1024 && (
          <div className={styles.musikerBildContainer}>
            <img src={musikerBild} className={styles.musikerBild} alt="Francesco Savignano - Portrait mit Kontrabass"/>
          </div>
        )}
      </section>

      <h2>Letztes Projekt</h2>
      <BlueLineSeparator width="30%" />

      <section className={styles.lastProject}>
        <img src={lastProjectImageURL} className={styles.bilder} alt="Bild vom letzten Projekt"/>

        <BlueBox height="520px" width="100%" padding="40px 0">
          <h3 className={styles.projectTitle}>{lastProjectTitle}</h3>
          <h4 className={styles.projectSubtitle}>{lastProjectSubtitle}</h4>
          <p className={styles.projectText}>
            {lastProjectText ? lastProjectText.substring(0, 200) : ""} ...
          </p>
          <Button
            aria-label="Mehr über letztes Projekt lesen"
            color="white"
            onClickFunction={onProjectModalClickHandler}
            isOn={isClosing}
          >
            Mehr Lesen
          </Button>
        </BlueBox>
      </section>

      <section>
        <BlueBox padding="5%" margin="0 0 20px 0">
          <div className={styles.lastNewsWrapper}>
            <img src={lastNewsImageURL} className={styles.lastNewsBild} alt="Bild der letzte News" />
            <div className={styles.lastNewsItem}>
              <h2 className="white" style={{ marginTop: "-10px" }}>
                Letzte News
              </h2>
              <WhiteLineSeparator width="50%" />
              <h3 className="white">{lastNewsTitle}</h3>
              <p className={styles.projectText}>
                {lastNewsText ? lastNewsText.substring(0, 200) : ""} ...
              </p>
              <Button
                aria-label="Mehr über letzte News lesen"
                color="white"
                onClickFunction={onNewsModalClickHandler}
                isOn={isClosing}
              >
                Mehr Lesen
              </Button>
            </div>
          </div>
        </BlueBox>
      </section>

      {(newsModalIsOn || projectModalIsOn) &&
        ReactDOM.createPortal(
          <Backdrop isOn={newsModalIsOn || projectModalIsOn} />,
          backdropPortal
        )}

    {(welcomePageIsOn && !dataCtx.wasAlreadyVisited) &&
        ReactDOM.createPortal(
          <Welcome
            onModalClose={onWelcomePageCloseHandler}
            isOn={isClosing}
          />,
          modal
        )} 

      {projectModalIsOn &&
        ReactDOM.createPortal(
          <ProjectModal
            role="dialog"
            aria-modal="true"
            aria-labelledby="project-modal-title"
            image={lastProjectImageURL}
            title={lastProjectTitle}
            content={lastProjectText}
            onModalClose={onCloseHandler}
            isOn={isClosing}
          />,
          modal
        )}

      {newsModalIsOn &&
        ReactDOM.createPortal(
          <NewsModal
            role="dialog"
            aria-modal="true"
            aria-labelledby="news-modal-title"
            image={lastNewsImageURL}
            title={lastNewsTitle}
            content={lastNewsText}
            onModalClose={onCloseHandler}
            isOn={isClosing}
          />,
          modal
        )}
    </Fragment>
  );
};

export default Home;
