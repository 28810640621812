import { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import BurgerMenu from "../UI/BurgerMenu";
import SliderMenu from "./SliderMenu";
import styles from './mobileNav.module.css';

const MobileNav = () => {

    const modal = document.getElementById('modal');

    const [sliderMenuToggle, setSliderMenuToggle] = useState (false);

    function sliderMenuToggleHandler () {
        setSliderMenuToggle(!sliderMenuToggle);
    }

    return(
        <Fragment>
             <SliderMenu isOn={sliderMenuToggle} onTrigger = {sliderMenuToggleHandler}/>
            <div className={styles.mobileNav} role="navigation">
            <h2 role="button"
                aria-label="menu" className={styles.menuTitle} 
                onClick = {sliderMenuToggleHandler} >MENU</h2>
            <BurgerMenu isOn = {sliderMenuToggle} onTrigger = {sliderMenuToggleHandler}/>
            
            </div>

        </Fragment>
    )
}

export default MobileNav;