import React from 'react';
import { Fragment } from 'react';
import styles from './footer.module.css';
import SocialNav from './nav/SocialNav';
import SubNav from './nav/SubNav';
import GoldLineSeparator from './UI/GoldLineSeparator';

const Footer = (props) => {

    return(
        <footer role="contentinfo">
            {props.viewport > 430 && <div className={styles.blocks}>
            <h1 className={styles.logo_Francesco}>Francesco</h1>
            <h1 className={styles.logo_Savignano}>Savignano</h1>
            <GoldLineSeparator width='80%' />
            <address className= {styles.address}>
                <h2 className={styles.addressLine}>Mulvanystr. 2</h2>
                <h2 className={styles.addressLine}>40239 Düsseldorf</h2>
                <h2 className={styles.addressLine}>0160 / 95240002</h2>
                <h2 className={styles.addressLine}>f_savignano@yahoo.de</h2>
            </address>
            </div>}

            {(props.viewport <= 680 && props.viewport > 430) && (
            <div className={styles.navigations}>
            <div className={styles.blocks}><SocialNav /></div>
            <div className={styles.blocks}>
               <SubNav/>
            </div>
            </div>)}
            {props.viewport > 680 &&
            (<Fragment ><div className={styles.blocks}><SocialNav /></div>
            <div className={styles.blocks}>
               <SubNav/>
            </div>
            </Fragment>)
            }

            {props.viewport <= 430 && (

                <div className={styles.blocks}>
                <h1 className={styles.logo_Francesco}>Francesco</h1>
                <h1 className={styles.logo_Savignano}>Savignano</h1>
                <GoldLineSeparator width='80%' />
                <address className= {styles.address}>
                <h2 className={styles.addressLine}>Mulvanystr. 2</h2>
                <h2 className={styles.addressLine}>40239 Düsseldorf</h2>
                <h2 className={styles.addressLine}>0160 / 95240002</h2>
                <h2 className={styles.addressLine}>f_savignano@yahoo.de</h2>
                </address>
                
            <div className={styles.navigations}>
            <SocialNav />
            
               <SubNav viewport = {props.viewport}/>
        
            </div>
            </div>
)}
            </footer>
    )
}

export default Footer;