import fLogo from '../../img/f_logo_gold.svg';
import instLogo from '../../img/inst_logo_gold.svg';
// import spLogo from '../../img/sp_logo_gold.svg';
// import twLogo from '../../img/tw_logo_gold.svg';
import styles from './socialNav.module.css';

const SocialNav = (props) => {
    return (
    <nav className={styles.socialNav}>
        <a href='https://www.facebook.com/francesco.savignano.77' target='_blank' rel='noreferrer' aria-label="go to facebook">
            <img className={styles.socialNavIcon} src ={fLogo} alt="Facebook Logo"></img>
            </a>
       <a href='https://www.instagram.com/savignano.francesco/' target='_blank' rel='noreferrer' aria-label="go to instagram">
        <img className={styles.socialNavIcon} src = {instLogo} alt= "Instagram Logo"></img>
        </a>
        {/* <img className={styles.socialNavIcon} src= {spLogo}></img>
        <img className={styles.socialNavIcon} src = {twLogo}></img>*/}
    </nav> 
    );
}

export default SocialNav;